<template>
    <div class="row mb-3">
        <div class="col-lg-12 mb-2">
            <div class="card w-100 bg-lightblue p-lg-4 pt-lg-5 border-0 rounded-lg d-block float-left">
                <img :src="user?.photo || '/images/profile.png'" alt="icon" class="sm-mt-2 w75 position-relative top--10 float-left mr-2 mt--1 rounded-circle">
                <h2 class="font-lg d-inline-block float-left mb-0 text-grey-900 fw-700">
                    <span class="font-xssss fw-600 text-grey-500 d-block mb-2 ml-1">{{user?.email}}</span>{{user?.name}}
                </h2>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group pt-4 border-light border p-2 shadow-xs bg-white rounded-lg">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="row m-0 p-0">    
                            <div class="form-group col-12 icon-input mb-0">
                                <div class="form-group col-lg-12">
                                    <select class="form-control" v-model="cycle" required>
                                        <option v-for="(item, index) in cycles" :key="index" :value="item.cycle">Cycle {{ item.cycle }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                        <div class="card-body w-100">
                            <div class="row">
                                <!-- <div class="col-lg-12">
                                    <h5 class="ml-3">Progress</h5>
                                    <apexchart height="250" width="100%" type="bar" :options="progress.options" :series="progress.series"></apexchart>
                                </div> 
                                <div class="col-lg-12">
                                    <h5 class="ml-3">Grading</h5>
                                    <apexchart height="250" width="100%" type="bar" :options="grading.options" :series="grading.series"></apexchart>
                                </div>  -->
                                <div class="col-lg-12 mb-3">
                                    <h3>Submission Activity</h3>
                                </div>
                                <div class="col-lg-12">
                                    <h5 class="ml-3">Total Submission</h5>
                                    <apexchart height="200" width="100%" type="bar" :options="submission.options" :series="submission.series"></apexchart>
                                    <div id="accordion" class="accordion mb-0">
                                        <div class="card shadow-xss mb-0">
                                            <div class="card-header bg-greylight theme-dark-bg" id="heading">
                                                <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link collapsed" data-toggle="collapse" data-target="#collapse" aria-expanded="false" aria-controls="collapsei"> Table of Submission</button></h5>
                                            </div>
                                            <div id="collapse" class="collapse p-0" aria-labelledby="heading" data-parent="#accordion">
                                                <div class="card-body d-flex p-0">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Unit</th>
                                                                <th>Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(item, n) in table_submission" :key="n">
                                                                <td>{{n + 1}}</td>
                                                                <td>{{item.content}}</td>
                                                                <td>{{item.submission}} Submission</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div class="col-lg-12">
                                    <div class="my-2">&nbsp;</div>
                                </div> 
                                <div class="col-lg-12">
                                    <h5 class="ml-3">Total Participation</h5>
                                    <apexchart height="200" width="100%" type="bar" :options="participant.options" :series="participant.series"></apexchart>
                                </div> 
                            </div>
                        </div>          
                    </div>
                </div>
            </div>
        </div>
    </div>          
</template>

<script>
import axios from 'axios';

export default {
    data(){
        return {
            slug: this.$route.params.idCourse,
            user_id: this.$route.params.idStudent,
            cycles: [],
            cycle: '',
            table_submission: [],
            submission: {
                options: {
                    chart: {
                        id: 'vuechart-example'
                    },
                    noData: {  
                        text: "Loading...",  
                        align: 'center',  
                        verticalAlign: 'middle',  
                        offsetX: 0,  
                        offsetY: 0,  
                        style: {  
                            color: "#000000",  
                            fontSize: '14px',  
                            fontFamily: "Helvetica"  
                        }  
                    },
                    
                },
                series: [],
            },
            participant: {
                options: {
                    chart: {
                        id: 'vuechart-example'
                    },
                    noData: {  
                        text: "Loading...",  
                        align: 'center',  
                        verticalAlign: 'middle',  
                        offsetX: 0,  
                        offsetY: 0,  
                        style: {  
                            color: "#000000",  
                            fontSize: '14px',  
                            fontFamily: "Helvetica"  
                        }  
                    },
                },
                series: [],
            },
            user: null
        }
    },
    watch: {
        cycle(){
            this.getSubmission() 
            this.getParticipant()
            this.getTableSubmission()
        }
    },
    created() {
        this.getCycles()
        this.getUser()
    },
    methods: {
        async getUser() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/user?id=${this.user_id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.user = res.data
            }).catch(() => {
                this.$swal({
                toast: true,
                title: 'Report',
                text: 'Failed Load User!',
                icon: 'error',
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                })
            })
        },
        async getCycles(){
            if(localStorage.getItem(`${this.slug}-cycles`)) {
                this.cycles = JSON.parse(localStorage.getItem(`${this.slug}-cycles`))
            } else {
                await axios.get(`${process.env.VUE_APP_URL_API}/core/course/cycles?slug=${this.slug}&is_active=true`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.cycles = res.data.data
                    for(let i = 0; i < this.cycles.length; i++) {
                        if(this.cycles[i].active) {
                            this.cycle = this.cycles[i].cycle
                            break;
                        }
                    }
                }).catch((err) => console.error(err))
            }
            if(localStorage.getItem(`${this.slug}-cycle`)) {
                this.cycle = localStorage.getItem(`${this.slug}-cycle`)
            } else {
                this.cycle = this.cycles[0].cycle
            }
            console.log('cycles', this.cycles)
            this.getSubmission()
            this.getParticipant()
            this.getTableSubmission()
        },
        async getSubmission() {
            this.submission = {
                options: {
                    chart: {
                        id: 'vuechart-example'
                    },
                    noData: {  
                        text: "Loading...",  
                        align: 'center',  
                        verticalAlign: 'middle',  
                        offsetX: 0,  
                        offsetY: 0,  
                        style: {  
                            color: "#000000",  
                            fontSize: '14px',  
                            fontFamily: "Helvetica"  
                        }  
                    },
                    
                },
                series: [],
            },
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/goal-analytic/submission?slug=${this.slug}&cycle=${this.cycle}&user_id=${this.user_id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.submission = res.data.data
            })
        },
        async getParticipant() {
            this.participant = {
                options: {
                    chart: {
                        id: 'vuechart-example'
                    },
                    noData: {  
                        text: "Loading...",  
                        align: 'center',  
                        verticalAlign: 'middle',  
                        offsetX: 0,  
                        offsetY: 0,  
                        style: {  
                            color: "#000000",  
                            fontSize: '14px',  
                            fontFamily: "Helvetica"  
                        }  
                    },
                },
                series: [],
            },
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/goal-analytic/participation?slug=${this.slug}&cycle=${this.cycle}&user_id=${this.user_id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.participant = res.data.data
            })
        },
        async getTableSubmission() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/goal-analytic/table-submission?slug=${this.slug}&cycle=${this.cycle}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.table_submission = res.data.data
            })
        },
    }
}
</script>